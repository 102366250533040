import React, { Component } from "react";

class ViewUser extends Component {
  render() {
    return (
      <div className="view-user-page">
        View User page
      </div>
    );
  }
}

export default ViewUser;
