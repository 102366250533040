const config = {
  appName: 'WildAid O-FISH',
  realmServiceName: 'mongodb-atlas',
  realmAppId: 'wildaidsandbox-mxgfy',
  database: 'wildaid',
  chartsConfig: {
    baseUrl: "https://charts.mongodb.com/charts-o-fish--incl--sandbox--gwemy",
    "boardings": {
      chartId: "0b3d736c-50dd-4c20-8211-b5f292e5ad21"
    },
    "boarding-compliance":{
      chartId: "6f9cc4ae-fb12-42db-b576-741355a5f9e1"
    },
    "patrol-hours":{
      chartId: "43503dab-7e68-4af1-8ab8-9def76f7bd7e"
    },
    "compliance-rate":{
      chartId: "780c6d31-8469-41fa-9f2f-334efbcc1080"
    },
    "boardings-count-chart":{
      chartId: "e9eee83a-7fec-43f4-8680-5dc8485c3eff"
    },
    "citations-and-warnings":{
      chartId: "d8e3ba55-8d31-4136-b914-8a058fbee590"
    }
  },
  sandbox: true,
};

module.exports = config;
