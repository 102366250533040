import React, { Component } from "react";

class UsersActivities extends Component {
  render() {
    return (
      <div className="activities-page">
        Acticities page
      </div>
    );
  }
}

export default UsersActivities;
