import React, { Component } from "react";

class UserGroup extends Component {
  render() {
    return (
      <div className="flex-row padding-top justify-center">
        User groups page
      </div>
    );
  }
}

export default UserGroup;
